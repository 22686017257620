// Import Fuse core library
// regular style toast
@import 'node_modules/ngx-toastr/toastr';
// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-material.css";
@import "ag-grid-community/dist/styles/ag-theme-alpine.css";
@import '../assets/styles/main-style.scss';
@import '~ngx-org-chart/_theming.scss';
.ag-theme-material {
    .ag-ltr {
        .ag-row-selected {
            background-color: rgba(33, 150, 243, 0.38);
        }
    }

    .ag-cell-inline-editing {
        padding: 24px 10px;

        .ag-cell-edit-input {
            padding: 0;
        }

        mat-form-field {
            width: 100%;

            .mat-form-field-infix {
                border: none;
                padding: 0;
            }
        }
    }
}

// aggird
$height-record-aggird-40: 40px !important;
$height-record-aggird-44: 44px !important;
$height-record-aggird-50: 50px !important;
$height-record-aggird-30: 30px !important;
$height-record-aggird-20: 20px !important;

.ag-theme-material-custom {
    .ag-theme-material {
        font-family: var(--font_app), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
        
        .ag-header-cell,
        .ag-header-group-cell {
            line-height: $height-record-aggird-40;
        }

        .ag-header-cell-menu-button .ag-icon-menu {
            height: $height-record-aggird-40;
        }

        .ag-header-row {
            height: $height-record-aggird-40;
            font-weight: 800;
        }

        .ag-header {
            height: $height-record-aggird-40;
            min-height: $height-record-aggird-40;
        }

        .ag-row {
            // height: $height-record-aggird-40;
            border-top: hidden;
            border-right: hidden;
        }

        .ag-theme-material .ag-cell {
            line-height: $height-record-aggird-40;
        }

        .ag-cell {
            line-height: $height-record-aggird-40;
            display: flex;
            align-items: center;
        }

        .ag-paging-panel {
            height: $height-record-aggird-44;
        }
    }
}

.ag-theme-material-custom-2 {
    .ag-theme-material {
        font-family: var(--font_app), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
        // .ag-header-cell,
        // .ag-header-group-cell {
        //     // line-height: $height-record-aggird-38;
        // }

        .ag-header-cell-menu-button .ag-icon-menu {
            height: $height-record-aggird-40;
        }

        .ag-header-row {
            height: $height-record-aggird-40;
            font-weight: 800;
        }

        .ag-header {
            height: $height-record-aggird-40;
            min-height: $height-record-aggird-40;
        }

        .ag-row {
            // height: $height-record-aggird-40;
            border-top: hidden;
            border-right: hidden;
        }

        .ag-theme-material .ag-cell {
            line-height: $height-record-aggird-40;
        }
        .ag-header-group-cell{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        .ag-header-cell{
            padding-left: 10px !important;
            padding-right: 10px !important;
        }

        .ag-cell {
            line-height: $height-record-aggird-40;
            display: flex;
            align-items: center;
            padding-right: 10px !important;
            padding-left: 10px !important;
        }

        .ag-paging-panel {
            min-height: 44px !important;
            height: 44px !important;
        }

    }
}

.error-snackbar {
    background: $error-background !important;
}

.complete-snackbar {
    background: $success-background !important;
}

.mat-snack-bar-container {
    text-align: center;
}

.mat-simple-snackbar {
    display: inline-block !important;
}

.mat-snack-bar-container {
    color: #ffffff !important;
}

.display-none {
    display: none !important;
}

.toast-container-error {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 350px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
    background-color: #BD362F
}

.height-stepper {
    height: calc(100vh - 150px);
    overflow: scroll;
}

.height-stepper-page {
    height: calc(100vh);
    overflow: hidden;
    width: calc(100vw);
}

.height-stepper-page-child {
    height: calc(100vh - 180px);
    overflow: auto;
}

.height-stepper-page-child-custom {
    // height: calc(100vh - 180px);
    overflow: auto;
}


// @media screen and (max-width: 600px) {
//     .height-stepper-page-child {
//         height: calc(100vh - 150px);
//     }
// }

.custom-dialog-container .mat-dialog-container {
    margin: 0px;
    padding: 0px;
}

.btn-disable {
    color: #000 !important;
    opacity: 1.0 !important;
}

.form-disable {

    .mat-input-element:disabled,
    .mat-form-field-disabled {
        color: #000 !important;
        opacity: 1.0 !important;
    }

    body.light .mat-input-element:disabled {
        color: #000 !important;
        opacity: 1.0 !important;
    }

    body.light .mat-select-disabled .mat-select-value {
        color: #000 !important;
        opacity: 1.0 !important;
    }

    .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text>* {
        color: #000 !important;
        opacity: 1.0 !important;
    }
}

.ag-root-wrapper-body.ag-layout-normal {
    height: auto;
}

.btn-color-main-action {
    background-color: #3b82f6;
}

.text-on-primary {
    color: #ffffff !important;
}

// .mat-flat-button.mat-primary{
//     color: #fff;
// }
body.light .mat-flat-button.mat-primary,
body.light .mat-raised-button.mat-primary,
body.light .mat-fab.mat-primary,
body.light .mat-mini-fab.mat-primary,
body .light .mat-flat-button.mat-primary,
body .light .mat-raised-button.mat-primary,
body .light .mat-fab.mat-primary,
body .light .mat-mini-fab.mat-primary {
    color: #fff !important;
}

body.light .mat-step-header .mat-step-icon-selected,
body.light .mat-step-header .mat-step-icon-state-done,
body.light .mat-step-header .mat-step-icon-state-edit,
body .light .mat-step-header .mat-step-icon-selected,
body .light .mat-step-header .mat-step-icon-state-done,
body .light .mat-step-header .mat-step-icon-state-edit {
    color: #fff;
}

body.light .mat-step-header .mat-step-icon,
body .light .mat-step-header .mat-step-icon {
    color: #fff;
}

.ag-root-wrapper .ag-watermark {
    display: none !important;
}

span .mat-placeholder-required {
    color: #BD362F !important;
}

.logo-parent-image {
    text-align: center;
    display: flex;
    margin: 10px 0px;
}

.logo-image {
    height: 48px;
    width: 48px;
    padding-left: 10px;
    padding-top: 0.5rem;
}

.text-logo {
    color: $main-text-color;
}

.text-logo-secondary {
    color: $secondary-text-color;
}

.remove-scroll .mat-dialog-container {
    overflow-y: hidden !important;
}

.btn-default {
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
}

.dock-bottom {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

.btn-border {
    border: 1px solid $color-header !important;
}

.header-page {
    max-height: 65px;
    background-color: $header-orange;
    display: flex;
    align-items: center;

    .title {
        display: flex;
        align-items: center;
        color: white;
        font-size: 18px;
        padding-left: 30px;
        font-weight: bold;

        .mat-icon {
            height: 30px;
            width: 30px;
        }
    }
}

.menu-active-parent {
    color: $main-text-color;
    font-weight: 700;
}


.menu-active-child {
    border: 1.5px solid $main-text-color;
    background: $main-text-color;
}

.title-menu-parent {
    color: $main-text-color !important;
    // font-weight: bold;
    font-weight: 700;
}
@media screen and (max-width: 720px) {
    .title-menu-parent {
        color: #22de6b !important;
        // font-weight: bold;
        font-weight: 700;
    }
}

.active-item {
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.05;
}

.background-app {
    background: #f4f4f4;
}

// .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex{
//     // .mat-input-element{
//     //     height: 38px;
//     //     min-height: 38px;
//     // }
//     height: 38px;
//     min-height: 38px;
// }

// ::ng-deep .mat-form-field {
//     font-size: 13px; // example
// }

.upload-file-ext2 {

    // .list-visible{
    //     .upload-input {
    //         display:none !important;
    //     }
    // }
    // .list-visible[_nghost-pya-c239] .upload-input[_ngcontent-pya-c239]{
    //     display:none !important;
    // }
    file-upload-drop-zone {
        display: none !important;
        cursor: none !important;
    }
}

// /* Sets the legend width to auto expand to fit labels, but caps at 200px */
// .chart-legend > div {
//     width: auto !important;
//     max-width: 200px;
// }

// /* Some extra space is needed to offset the way the labels display */
// .legend-new{
//     .legend-labels {
//         width: 100px !important;
//     }

//     .legend-title{
//         width: 100px !important;
//     }
// }

// .legend-new-2{
//     .legend-labels {
//         font-size: 16px;
//         width: 200px !important;
//     }

//     .legend-title{
//         font-size: 16px;
//         width: 200px !important;
//     }
// }

// /* Wrap the label text if it ends up being longer than the max width */
// .legend-label-text {
//     white-space: normal !important;
//     width: 100% !important;
// }

.mat-form-field-invalid {
    .mat-form-field-infix {
        color: var(--fuse-warn) !important;
    }

    .mat-select-value {
        color: var(--fuse-warn) !important;
    }

    .mat-select-arrow {
        color: var(--fuse-warn) !important;
    }
}

@media screen and (max-width: 600px) {
    .ag-grid-list {
        height: calc(100vh - 200px) !important;
    }
}

.complete-snackbar-with-action {
    background: $success-background !important;

    .mat-simple-snackbar-action button {
        margin-top: 20px !important;
        background-color: white !important;
    }
}

.margin-bottom-7-5rem {
    margin-bottom: 7.5rem !important;
}

.margin-bottom-page {
    bottom: 4rem !important;
    // bottom: 5px !important;
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

/* ----------- iPhone X ----------- */

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

// 11
/* 1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

// 11 pro
/* 2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}
// iPhone 11 Pro Max
/* 2688x1242px at 458ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

// iPhone 13 and iPhone 13 Pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

// iPhone 13 Pro Max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

@media screen and (max-width: 600px) {
    .margin-bottom-page {
        bottom: 115px !important;
    }
}

.multiple-app {
    .parent {
        margin: 0;
        padding: 26px;
        width: 265px;
    }

    .children-item {
        display: inline-block;
        height: 98px;
        list-style-type: none;
        padding: 1px;
        position: relative;
        -webkit-transition: transform .2s cubic-bezier(.333, 0, 0, 1);
        transition: transform .2s cubic-bezier(.333, 0, 0, 1);
        vertical-align: top;
        width: 86px;
    }

    .child-item {
        margin: 8px 2px;
        outline: none;
        position: absolute;
        text-align: center;
        text-decoration: none;
    }

    .tX9u1b {
        margin: 8px 2px;
        outline: none;
        position: absolute;
        text-align: center;
        text-decoration: none;
    }

    .MrEfLc {
        display: inline-block;
        height: 64px;
        vertical-align: top;
        width: 64px;
        // background-image: url(https://ssl.gstatic.com/gb/images/p1_6234ef59.png);
        // background-size: 64px 2686px;
    }

    .Rq5Gcb {
        padding-top: 7px;
        font-weight: bold;
        color: #404040;
        display: block;
        // font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 84px;
    }

    .j1ei8c {
        display: inline-block;
        height: 98px;
        list-style-type: none;
        padding: 1px;
        position: relative;
        -webkit-transition: transform .2s cubic-bezier(.333, 0, 0, 1);
        transition: transform .2s cubic-bezier(.333, 0, 0, 1);
        vertical-align: top;
        width: 86px;
        cursor: pointer;
    }

    .tX9u1b:hover {
        border: 1px solid #dedede;
    }

    .LVal7b {
        margin: 0;
        padding: 26px;
        width: 265px;
        background: white;
        // text-align: center;
    }

    .card {
        /* Add shadows to create the "card" effect */
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
    }

    /* On mouse-over, add a deeper shadow */
    .card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    /* Add some padding inside the card container */
    .container {
        padding: 2px 16px;
    }
}

.header-case {
    width: 250px;

    .span-header {
        font-size: 14px;
        opacity: 0.8;
    }
}

.content-case {
    .span-content {
        font-size: 14px;
    }
}